<template>
  <div class="search">
    <div class="location">
      <i class="el-icon-place"></i>
      <el-breadcrumb
        class="el__breadcrumb"
        separator-class="el-icon-arrow-right"
      >
        <el-breadcrumb-item>您当前的位置</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>搜索</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <ul class="list">
      <li @click="onDetail(item)" v-for="item in askList" :key="item.id">
        <div class="content">
          <div class="title">
            <div class="tag">问</div>
            <span class="txt">{{ item.title }}</span>
          </div>
          <div class="desc">
            {{ item.describe | formatText }}
          </div>
          <div class="next">
            <div class="lt">
              <span>{{ item.userName }}</span>
              <span>对</span>
              <span>{{ item.deptName }}</span>
              <span>说</span>
            </div>
            <div class="rt">{{ item.handelTime }}</div>
          </div>
        </div>

        <el-image
          v-if="item.status >= 20"
          class="pos__icon"
          :src="require('images/online/reply.png')"
        ></el-image>
        <el-image
          v-if="item.status == 10"
          class="pos__icon"
          :src="require('images/online/accept.png')"
        ></el-image>
        <el-image
          v-if="item.status == 1 || item.status == 6"
          class="pos__icon"
          :src="require('images/online/reject.png')"
        ></el-image>
      </li>
    </ul>
    <el-pagination
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="currentSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
  </div>
</template>

<script>
import { getAskList } from "@/api/wzasksevent";
import { htmlToText } from "@/utils/tools";
import { mapState } from "vuex";
export default {
  name: "search",
  computed: {
    ...mapState({
      keyword: (state) => state.keyword,
    }),
  },
  data() {
    return {
      currentPage: 1,
      currentSize: 10,
      total: 0,
      askList: [],
    };
  },
  watch: {
    keyword: {
      handler(val) {
        this.getAskList(val);
      },
      immediate: true,
    },
  },
  filters: {
    formatText(val) {
      return htmlToText(val);
    },
  },
  methods: {
    onDetail(item) {
      this.$router.push({
        path: "/web/detail/",
        query: {
          id: item.id,
        },
      });
    },
    async getAskList() {
      const res = await getAskList(this.currentPage, this.currentSize, {
        title: this.keyword,
        orderType: 2,
      });
      // console.log(res);
      this.askList = res.data.records;
      this.total = res.data.total;
    },
    handleSizeChange(val) {
      this.currentSize = val;
      this.getAskList();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getAskList();
    },
  },
};
</script>

<style lang="scss" scoped>
.search {
  background: #ffffff;
  .location {
    padding: 20px;
    display: flex;
    align-items: center;
    .el__breadcrumb {
      margin-left: 8px;
    }
  }
  .list {
    li {
      box-shadow: 0px -1px 0px 0px #f0f2f5 inset;
      position: relative;
      .content {
        padding: 20px;
        .title {
          display: flex;
          align-items: center;
          .tag {
            font-size: 13px;
            color: #5cbd31;
            padding: 5px;
            border-radius: 5px;
            border: 1px solid #5cbd31;
          }
          .txt {
            font-size: 18px;
            color: #333333;
            font-weight: 700;
            margin-left: 8px;
          }
        }
        .desc {
          font-size: 14px;
          color: #999999;
          line-height: 26px;
          margin-top: 14px;
          word-break: break-all;
          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        .next {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 15px;
          .lt {
            font-size: 14px;
            span:nth-child(odd) {
              color: #52545b;
            }
            span:nth-child(even) {
              color: #999999;
              padding: 0 14px;
            }
          }
          .rt {
            font-size: 13px;
            color: #999999;
          }
        }
      }
      .pos__icon {
        width: 68px;
        position: absolute;
        right: 20px;
        top: 20px;
      }
    }
  }
  .el-pagination {
    padding: 20px;
  }
}
</style>
